ShopCart = {
    selector: "#shop-cart-modal",
    callback: null,
    init: function (callback) {
        $('.shop-cart-modal-dialog').show();
        $(this.selector).show();
        if (callback instanceof Function) {
            this.callback = callback
        }

        $.get('/shop/checkout/modal_data', function (response) {
            $('.cart-modal-content').html(response);
            if (ShopCart.callback instanceof Function) {
                ShopCart.callback()
            }
        })
    },
    close: function () {
        $(this.selector).hide();
    }
}
var pid = null;
$(document).ready(function () {
    $('body').on('click', '#shop-cart-modal .count-manipulate', function () {
        var pType = $(this).attr('ptype');
        var pId = $(this).attr('pid');
        var pCount = 1;
        var url = '/shop/checkout/countable/' + pId + '/' + pType + '/' + pCount;
        $.get(url, function () {
            ShopCart.init();
        });

    })
    $('body').on('click', '#shop-cart-modal .close-modal', function () {
        ShopCart.close();
    })

    $('body').on('change', '#shop-cart-modal .count-value', function () {
        var pType = 'manual';
        var pId = $(this).attr('pid');
        var pCount = $(this).val();
        var url = '/shop/checkout/countable/' + pId + '/' + pType + '/' + pCount;
        $.get(url, function () {
            ShopCart.init();
        });
    })
    $('body').on('click', '#shop-cart-modal .delete-from-cart', function () {
        var pId = $(this).attr('pid');
        var url = '/shop/checkout/cart/' + pId + '/delete';
        $.get(url, function () {
            ShopCart.init(function () {
                $.get('/shop/checkout/data').done(function (htmlResp) {
                    $('#cart').html(htmlResp)
                });
            });

        });
    })
    $('body').on('click', '.delete-from-cart-promt', function () {
        pid = $(this).attr('pid');
        $('#removeModal').modal('show')
    })
    $('body').on('click', '#shop-cart-modal .continue-shopping', function () {
        ShopCart.close()
    })

    $('#mini-cart-block').click(function () {
        ShopCart.init()
    })
});

function removeFromCartProduct() {

    $.ajax({
        url: '/shop/checkout/cart/' + pid + '/delete',
        type: 'get',
        success: function (result) {
            $(".cart-total-sum").text(result['cartTotalSum']);
            $("#uniqcode-" + pid).remove();
        }
    });
}
