$(document).ready(function () {
    $('.so-categories').on('click', '.button-category-view.closed', function (e) {
        console.log($(this));
        $(this).removeClass('closed');
        $(this).addClass('opened');
        $(this).parent().find('.ch').removeClass('hidden');
        var f = $(this).find('.fa');
        f.removeClass('fa-angle-down');
        f.addClass('fa-angle-up');
        $(this).html('Скрыть  <span class="fa fa-angle-up"></span>')
    });
    $('.so-categories').on('click', '.button-category-view.opened', function (e) {
        $(this).removeClass('opened');
        $(this).addClass('closed');
        $(this).parent().find('.ch').addClass('hidden');
        var f = $(this).find('.fa');
        $(this).html('Еще  <span class="fa fa-angle-down"></span>');
        f.removeClass('fa-angle-up');
        f.addClass('fa-angle-down')
    });
    var wModal = $('#welcomeModal');
    if (wModal.length) {
        wModal.modal('show');
    }
    $(".hide-btns").click(function () {
        $(this).toggleClass("hide-opened");
        $(".footer-second-seo-block").toggleClass("hide-text");
    });
});

function addNotice(title, thumb, text, type) {

    var stack_bar_top = {"dir1": "down", "dir2": "right", "push": "top", "spacing1": 0, "spacing2": 0};
    new PNotify({
        title: title,
        text: text,
        addclass: "stack-bar-top",
        cornerclass: "",
        width: "100%",
        delay: 6000,
        type: type,
        stack: stack_bar_top
    });
}


// PROFILE
$(document).ready(function () {
    if (jQuery().mask) {
        $('input[name="phone"]').mask('(099)999-99-99');
    }
    $('.emp-block').on('click', '.hidden-tab-btn.active', function () {
        var b = $(this);
        b.removeClass('active').removeClass('fa-chevron-circle-down').addClass('open');
        b.addClass('fa-chevron-circle-up');
        var tab = b.parent().parent().find('.hidden-tab');
        tab.removeClass('hidden')
    });
    $('.emp-block').on('click', '.hidden-tab-btn.open', function () {
        var b = $(this);
        b.removeClass('open').removeClass('fa-chevron-circle-up').addClass('active');
        b.addClass('fa-chevron-circle-down');
        var tab = b.parent().parent().find('.hidden-tab');
        tab.addClass('hidden')
    });
    if (typeof WOW === 'function') {
        var wow = new WOW(
            {
                boxClass: 'wow',
                animateClass: 'animated',
                mobile: true,
            }
        );
        wow.init()
    }

    // ADD NEW EMPLOYER
    if (jQuery().magnificPopup) {
        $('.add-new-employer').magnificPopup({
            items: {
                src: '#create-employer',
                type: 'inline'
            },
            closeBtnInside: false
        });

        $('body').on('click', '.close-mf-btn', function (e) {
            e.preventDefault();
            $.magnificPopup.close();
        });

        // ADD NEW EMPLOYER
        $('.reset-password').magnificPopup({
            items: {
                src: '#modal-reset-password',
                type: 'inline'
            },
            closeBtnInside: false
        });
    }

    var hash = location.hash.replace('#', '');
    var tab = $('.tabsslider .nav li[data-hash="' + hash + '"]');
    if (tab.length > 0) {
        tab.find('a').trigger('click');
    }


    $('body').on('click', '.close-mf-btn', function (e) {
        e.preventDefault();
        $.magnificPopup.close();
    });

    $('.tabsslider .nav li').click(function () {
        var hash = $(this).attr('data-hash');
        if (hash) {
            location.hash = hash;
        }
    });

    $(window).bind('hashchange', function (event) {
        var hash = location.hash.replace('#', '');
    });

    // MOBILE MAIN MENU
    $("#button-menu-mobile").click(function (e) {
        e.preventDefault();
        $("#megamenu-menu-mobile").addClass('menu-mobile-active');
    });
    $("#remove-menu-mobile").click(function (e) {
        e.preventDefault();
        $("#megamenu-menu-mobile").removeClass('menu-mobile-active');
    })
});

$('document').ready(function () {
    $('#one-click-order button[type="submit"]').on('click', function (e) {
        e.preventDefault();
        var phone = $(this).parent().find('input[name="phone"]').val();
        if (phone.length > 4) {
            var form = $(this).closest('#one-click-order');
            $.post(form.attr('action'), form.serialize());
            $('#one-click-modal').modal('hide');
            $('#one-click-modal-success').modal('show')
        }
    })
});
// GA
$('document').ready(function () {
    // MAIN PAGE TOP 5
    var topFiveProducts = [];
    $('.main-page .latest-product .product-latest-item').each(function (index) {
        topFiveProducts.push({
            'id': $(this).attr('data-sku'),
            'name': $(this).find('h4').text(),
            'brand': $(this).attr('data-brand'),
            'list': 'Top 5. Main page',
            'position': ++index
        })
    });
    if (topFiveProducts.length > 0) {
        dataLayer.push({
            "event": "productImpression",
            "ecommerce": {
                "currencyCode": "UAH",
                "impressions": topFiveProducts
            }
        })
    }

    // MAIN PAGE TOP BY CATEGORIES
    var tc = '.top-by-category';
    var tn = 'Top by category. Main page';
    gaCollectTopByCategoryProducts(tc, tn);
    $('.top-by-category .ltabs-tab').on('click', function() {
        setTimeout(function () {
            gaCollectTopByCategoryProducts(tc, tn)
        }, 400)
    });

    // MAIN PAGE TOP BY CATEGORIES
    var nc = '.new-category';
    var nn = 'New by category. Main page';
    gaCollectTopByCategoryProducts(nc, nn);
    $('.new-category .ltabs-tab').on('click', function() {
        setTimeout(function () {
            gaCollectTopByCategoryProducts(nc, nn)
        }, 400)
    });

    // ON PRODUCT CLICK
    $('.product-layout a').on('click', function (e) {
        var p = $(this).closest('.product-item-container');
        var list = p.attr('data-list').length > 0 ? p.attr('data-list') : 'Unknown';
        dataLayer.push({
            "event": "productClick",
            "ecommerce": {
                "click": {
                    "actionField": {
                        "list": list
                    },
                    "products": [{
                        "id": p.attr('data-sku'),
                        "name": p.attr('data-name'),
                        "price": p.attr('data-price'),
                        "brand": p.attr('data-brand'),
                        "list": "Main catalog",
                        "position": $('.product-item-container').index(p),
                    }]
                }
            }
        });
    })


});

// MAIN PAGE TOP BY CATEGORIES
function gaCollectTopByCategoryProducts(filter, categoryName) {

    var products = [];
    $(filter).find('.ltabs-items-selected .product-item-container').each(function (index) {
        products.push({
            'id': $(this).attr('data-sku'),
            'name': $(this).find('h4').text(),
            'brand': $(this).attr('data-brand'),
            'list': categoryName,
            'position': ++index
        })
    });
    if (products.length > 0) {
        dataLayer.push({
            "event": "productImpression",
            "ecommerce": {
                "currencyCode": "UAH",
                "impressions": products
            }
        })
    }
}

// Instantiate the Bloodhound suggestion engine
var movies = new Bloodhound({
    datumTokenizer: function (datum) {
        return Bloodhound.tokenizers.whitespace(datum.value);
    },
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    remote: {
        url: '/api/v1/shop/search?search=%QUERY',
        wildcard: '%QUERY',
        filter: function (data) {
            return data;
        },
        rateLimitWait: 300,

    },
    limit: 30
});

// Initialize the Bloodhound suggestion engine
movies.initialize();

// Instantiate the Typeahead UI
$('.typeahead').typeahead({
    highlight: true
}, {
    displayKey: 'value',
    source: movies.ttAdapter(),
    templates: {
        suggestion: Handlebars.compile("<div class='rm-search-block'><img class='rm-search-img' src='{{image}}' /><p style='padding-left:16px'>{{name}}  {{#if price}}- <b>{{price}} {{currency.name}}{{/if}}</b></p><div class='clearfix'></div></div>"),
        empty: Handlebars.compile('<div><strong>По вашему запросу ничего не найдено</strong></div>')
    }
}).on('typeahead:selected typeahead:autocompleted', function (e, datum) {
    location.href = datum.link
});
$(document).ready(function () {
    $("#sticker-mobile").click(function () {
        $(".type_2").toggleClass("type_2-show");
    });
    $(".arrow_close").click(function () {
        $(".type_2").removeClass("type_2-show");
    });
});