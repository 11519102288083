	var cart = {
	    'cartResult': false,
	    'addThxPage': function(product_id, quantity) {
            this.add(product_id, quantity);
            $('.page-thank .btn.btn-fast-order').show(200)
        },
	    'fastOrder': function(code) {
            $.post('/shop/order/fast-order/'+code).done(function (response) {
                if (response.success == true) {
                    fbq('track', 'AddToCart');
                    $.get('/shop/checkout/data').done(function (htmlResp) {
                        $('#cart').html(htmlResp)
                        addNotice('Вы успешно оформили заказ! В ближайшее время наш менеджер свяжется с вами', '', '', 'success')
                        cart.cartResult  = true;
                        $('.btn-fast-order').hide();
                        $('.product-count').val(1)
                    });
                } else {
                    addProductNotice('Ошибка', '', '', 'error');
                }

            })
                .fail(function () {
                    addProductNotice('Ошибка при добавлении товара', '', '', 'error');
                });


        },
        'basic_add': function(product_id, quantity, action) {
            var imgSrc = $('.product-image-zoom').attr('src');
            var cartForm = $('#add-to-cart');
            if ($('#add-to-cart-'+product_id).length > 0) {
                cartForm = $('#add-to-cart-'+product_id)
            }
            if (!imgSrc) {
                imgSrc = $('#product-image-'+product_id).attr('src');
            }
            cart.cartResult = false;
            $.post(
                action || cartForm.attr('action'),
                cartForm.serialize()
            ).done(function (response) {
                if (response.status == 'success') {
                    fbq('track', 'AddToCart');
                    $.get('/shop/checkout/data').done(function (htmlResp) {
                        $('#cart').html(htmlResp);
                        ShopCart.init();
                        cart.cartResult  = true;
                    });

                } else {
                    addProductNotice('Ошибка', response.data, '', 'error');
                }

            })
                .fail(function () {
                    addProductNotice('Ошибка при добавлении товара', 'Заполните все обязательные поля', '', 'error');
                });
            return cart.cartResult;
        },
		'add': function(product_id, quantity) {
            var imgSrc = $('.product-image-zoom').attr('src');
            var cartForm = $('#add-to-cart');
            if ($('#add-to-cart-'+product_id).length > 0) {
                cartForm = $('#add-to-cart-'+product_id)
            }
            if (!imgSrc) {
                imgSrc = $('#product-image-'+product_id).attr('src');
            }
            cart.cartResult = false;
            $.post(
                cartForm.attr('action'),
                cartForm.serialize()
            ).done(function (response) {
                if (response.status == 'success') {
                    fbq('track', 'AddToCart');
                    $.get('/shop/checkout/data').done(function (htmlResp) {
                        $('#cart').html(htmlResp)
                        addProductNotice('Товар успешно добавлен в корзину', '<img src="' + imgSrc + '">', '<h3><a href="/shop/checkout/cart">'+response.data.name+'</a> добавлен в <a href="/shop/checkout/cart">корзину</a>!</h3>', 'success');
                         cart.cartResult  = true;
                    });

                } else {
                    addProductNotice('Ошибка', response.data, '', 'error');
                }

            })
                .fail(function () {
                    addProductNotice('Ошибка при добавлении товара', 'Заполните все обязательные поля', '', 'error');
                });
            return cart.cartResult;
		},
		'fast-add': function(product_id, quantity) {
            var imgSrc = $('.product-image-zoom').attr('src');
            var cartForm = $('#add-to-cart, .add-to-cart');
            $.post(
                cartForm.attr('action'),
                cartForm.serialize()
            ).done(function (response) {
                if (response.status == 'success') {
                    $.get('/shop/checkout/data').done(function (htmlResp) {
                        $('#cart').html(htmlResp)
                        addProductNotice('Товар успешно добавлен в корзину', '<img src="' + imgSrc + '">', '<h3><a href="/shop/checkout/cart">'+response.data.name+'</a> добавлен в <a href="#">корзину</a>!</h3>', 'success');
                    });

                } else {
                    addProductNotice('Ошибка', response.data, '', 'error');
                }

            })
                .fail(function () {
                    addProductNotice('Ошибка при добавлении товара', 'Заполните все обязательные поля', '', 'error');
                });
		},
        'remove': function(code) {
            $.get(
                '/shop/checkout/cart/'+code+'/delete'
            ).done(function () {
                $.get('/shop/checkout/data').done(function (htmlResp) {
                    $('#cart').html(htmlResp)
                });

            });
        }
	}

	var wishlist = {
		'add': function(product_id) {
			addProductNotice('Product added to Wishlist', '<img src="image/demo/shop/product/e11.jpg" alt="">', '<h3>You must <a href="#">login</a>  to save <a href="#">Apple Cinema 30"</a> to your <a href="#">wish list</a>!</h3>', 'success');
		}
	}
	var compare = {
		'add': function(product_id) {
			addProductNotice('Product added to compare', '<img src="image/demo/shop/product/e11.jpg" alt="">', '<h3>Success: You have added <a href="#">Apple Cinema 30"</a> to your <a href="#">product comparison</a>!</h3>', 'success');
		}
	}

	/* ---------------------------------------------------
		jGrowl – jQuery alerts and message box
	-------------------------------------------------- */
	function addProductNotice(title, thumb, text, type) {
		$.jGrowl.defaults.closer = false;
		//Stop jGrowl
		//$.jGrowl.defaults.sticky = true;
		var tpl = thumb + '<h3>'+text+'</h3>';
		$.jGrowl(tpl, {
			life: 4000,
			header: title,
			speed: 'slow',
			theme: type
		});
    }

